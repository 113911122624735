@import "tokens";
@import "@mtgame/ui/styles/mixins";

@mixin transition($property) {
  transition: $property $transition-func $transition-time;
}

@mixin desktop-media {
  @media (min-width: $tabletBreakpoint) {
    @content
  }
}

@mixin panel() {
  background: $pattern-panel $color-panel;
  border-radius: $radius-card;
  box-shadow: $style-card-shadow;
  color: $color-panel-font;

  --color-colorfont: var(--color-panel-font);
  --color-textsecondary: var(--color-panel-font-muted);
  --color-controlelement: var(--color-panel-font-muted);
  --color-textfield: var(--color-panel-font);
}


@mixin panel-header() {
  display: flex;
  align-items: center;
  padding: 0 $spacing-m;
  border-radius: $radius-card $radius-card 0 0;
  height: $size-xl;
  background: $pattern-panel $color-panel-header;
  color: $color-panel-header-text;
  @include font-h4();

  --color-colorfont: var(--color-panel-font);
}

@mixin card() {
  background: $pattern-card $color-cardbase;
  border-radius: $radius-card;
  box-shadow: $style-card-shadow;
  color: $color-card-font;

  --color-colorfont: #{$color-card-font};
  --color-textsecondary: #{$color-card-font-muted};
}
