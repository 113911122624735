@import "tokens";
@import "mixins";

*:focus,
*:active {
  outline: none;
}
html {
  height: 100%;
  background: $color-background;
  color: lighten(#334a6f, 36%);
}
body {
  background: $pattern-bg $color-background;
  @include font-text-body();
  color: $color-colorfont;
  -webkit-overflow-scrolling: touch;
  &.dialog-opened {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    pointer-events: none;
  }
  &.no-scroll {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
}

.croppie-container .cr-viewport, .croppie-container .cr-resizer {
  box-shadow: none!important;
}

.empty-photo {
  @include centered-flex();
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: $pattern-controlbase $color-controlbase;
  color: $color-control;
  text-align: center;
  text-transform: uppercase;
}

.empty-logo {
  position: relative;
  @include centered-flex();
  width: 100%;
  height: 100%;
}

.empty-logo-basketball {
  background: url(/assets/empty-logo-basketball.png) no-repeat center;
  background-size: contain;
}

.empty-logo-volleyball {
  background: url(/assets/empty-logo-volleyball.png) no-repeat center;
  background-size: contain;
}

.empty-logo-hockey {
  background: url(/assets/empty-logo-hockey.png) no-repeat center;
  background-size: contain;
}

.circle-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.dialog-content {
  position: relative;
  margin: auto;
}

.visible-mobile {
  display: none;
}

@include tablet-media {
  .visible-mobile {
    display: block;
  }

  .hidden-mobile {
    display: none;
  }
}

h1 {
  margin: 0 0 $spacing-l;
  @include font-h1();
}
h2 {
  @include font-h2();
}
h3 {
  @include font-h3();
}
h4 {
  @include font-h4();
}
h5 {
  @include font-h5();
}
h6 {
  @include font-h6();
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.btn-detail {
  @include centered-flex();
  margin-left: auto;
  height: 36px;
  padding: 0 24px;
  border: 2px solid $color-colorfont;
  border-radius: 5px;
  @include font-text-bodybold();
  @include transition(all);
  &:hover {
    border: 2px solid $color-secondary;
    background: $color-secondary;
    color: $color-secondary-text;
  }
  @include phone-media {
    width: 100%;
    margin: 0;
  }
}

.card {
  background: $pattern-card $color-cardbase;
}

.panel {
  background: $pattern-panel $color-panel;

  > .header {
    background: $pattern-panel $color-panel-header;
  }
}
