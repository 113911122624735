@import "tokens";
@import "mixins";

.link-tabs {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin: 0 0 15px;
  a, span {
    color: $color-textsecondary;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;
    @include transition(all);

    &+a {
      margin-left: 25px;
    }
  }
  a {
    &.active {
      border-bottom-color: $color-primary;
      color: $color-colorfont;
    }
    &:hover {
      color: $color-colorfont;
    }
  }
}
