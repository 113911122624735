$color-alert: #c82a2a;
$color-background: #1B1B25;
$color-cardbase: #21212D;
$color-chipsprimary: #c82a2a;
$color-chipssecondary: #353646;
$color-colorfont: #ffffff;
$color-control: #ffffff;
$color-controlbase: #31313F;
$color-controlborder: rgba(82, 85, 98, 0.64);
$color-controlelement: #525562;
$color-iconbutton: #6f727c;
$color-link: #e04141;
$color-opacity: rgba(255, 255, 255, 0.16);
$color-overlay: rgba(22, 23, 33, 0.96);
$color-panel: #242531;
$color-primary: #e04141;
$color-primarybuttonfocus: rgba(224, 65, 65, 0.4);
$color-primaryhover: rgba(224, 65, 65, 0.48);
$color-secondarybuttonfocus: rgba(82, 85, 98, 0.2);
$color-separator: rgba(153, 153, 153, 0.16);
$color-textsecondary: #616974;
$color-white: #ffffff;
$size-l: 48px;
$size-m: 36px;
$size-s: 24px;
$size-xl: 56px;
$size-xs: 16px;
$size-xxl: 64px;
$size-xxs: 12px;
$size-xxxl: 72px;
$size-xxxxl: 80px;
$spacing-l: 48px;
$spacing-m: 36px;
$spacing-s: 24px;
$spacing-xl: 48px;
$spacing-xs: 16px;
$spacing-xxl: 64px;
$spacing-xxs: 12px;
$spacing-xxxl: 72px;
$spacing-xxxs: 8px;
$spacing-xxxxl: 80px;
$border-m: 4px;
$border-s: 2px;
$border-xs: 1px;
$border-xs-dashed: 1px;
$radius-button: 6px;
$radius-card: 8px;
$radius-control: 8px;
$radius-rounded: 1000px;
$style-elevate: 0px 24px 80px rgba(0, 0, 0, 0.2);
$font-control-button-font-size: 14px;
$font-control-button-font-weight: 700;
$font-control-button-line-height: 20px;
$font-control-button-text-transform: uppercase;
$font-control-errortext-font-size: 10px;
$font-control-errortext-font-weight: 400;
$font-control-errortext-line-height: 12px;
$font-control-focuslabel-font-size: 10px;
$font-control-focuslabel-font-weight: 400;
$font-control-focuslabel-line-height: 12px;
$font-control-label-font-size: 14px;
$font-control-label-font-weight: 400;
$font-control-label-line-height: 20px;
$font-control-placeholder-font-size: 14px;
$font-control-placeholder-font-weight: 400;
$font-control-placeholder-line-height: 20px;
$font-control-smallbutton-font-size: 12px;
$font-control-smallbutton-font-weight: 700;
$font-control-smallbutton-line-height: 16px;
$font-control-smallbutton-text-transform: uppercase;
$font-control-tab-font-size: 18px;
$font-control-tab-font-weight: 700;
$font-control-tab-line-height: 24px;
$font-family-base: Inter;
$font-h1-font-size: 44px;
$font-h1-font-weight: 700;
$font-h1-line-height: 56px;
$font-h2-font-size: 34px;
$font-h2-font-weight: 700;
$font-h2-line-height: 40px;
$font-h3-font-size: 28px;
$font-h3-font-weight: 700;
$font-h3-line-height: 32px;
$font-h4-font-size: 18px;
$font-h4-font-weight: 700;
$font-h4-line-height: 24px;
$font-h5-font-size: 14px;
$font-h5-font-weight: 700;
$font-h5-line-height: 20px;
$font-h6-font-size: 12px;
$font-h6-font-weight: 700;
$font-h6-line-height: 16px;
$font-mobile-h1-font-size: 28px;
$font-mobile-h1-font-weight: 700;
$font-mobile-h1-line-height: 32px;
$font-mobile-h2-font-size: 24px;
$font-mobile-h2-font-weight: 700;
$font-mobile-h2-line-height: 28px;
$font-mobile-h3-font-size: 20px;
$font-mobile-h3-font-weight: 700;
$font-mobile-h3-line-height: 24px;
$font-mobile-h4-font-size: 18px;
$font-mobile-h4-font-weight: 700;
$font-mobile-h4-line-height: 24px;
$font-mobile-h5-font-size: 14px;
$font-mobile-h5-font-weight: 700;
$font-mobile-h5-line-height: 20px;
$font-mobile-h6-font-size: 12px;
$font-mobile-h6-font-weight: 700;
$font-mobile-h6-line-height: 16px;
$font-text-body-font-size: 14px;
$font-text-body-font-weight: 400;
$font-text-body-line-height: 20px;
$font-text-bodybold-font-size: 14px;
$font-text-bodybold-font-weight: 700;
$font-text-bodybold-line-height: 20px;
$font-text-description-font-size: 10px;
$font-text-description-font-weight: 400;
$font-text-description-line-height: 12px;
@mixin font-control-button() {
  font: $font-control-button-font-weight #{$font-control-button-font-size}/#{$font-control-button-line-height} $font-family-base;
  text-transform: $font-control-button-text-transform;
}
@mixin font-control-errortext() {
  font: $font-control-errortext-font-weight #{$font-control-errortext-font-size}/#{$font-control-errortext-line-height} $font-family-base;
}
@mixin font-control-focuslabel() {
  font: $font-control-focuslabel-font-weight #{$font-control-focuslabel-font-size}/#{$font-control-focuslabel-line-height} $font-family-base;
}
@mixin font-control-label() {
  font: $font-control-label-font-weight #{$font-control-label-font-size}/#{$font-control-label-line-height} $font-family-base;
}
@mixin font-control-placeholder() {
  font: $font-control-placeholder-font-weight #{$font-control-placeholder-font-size}/#{$font-control-placeholder-line-height} $font-family-base;
}
@mixin font-control-smallbutton() {
  font: $font-control-smallbutton-font-weight #{$font-control-smallbutton-font-size}/#{$font-control-smallbutton-line-height} $font-family-base;
  text-transform: $font-control-smallbutton-text-transform;
}
@mixin font-control-tab() {
  font: $font-control-tab-font-weight #{$font-control-tab-font-size}/#{$font-control-tab-line-height} $font-family-base;
}
@mixin font-h1() {
  font: $font-h1-font-weight #{$font-h1-font-size}/#{$font-h1-line-height} $font-family-base;
}
@mixin font-h2() {
  font: $font-h2-font-weight #{$font-h2-font-size}/#{$font-h2-line-height} $font-family-base;
}
@mixin font-h3() {
  font: $font-h3-font-weight #{$font-h3-font-size}/#{$font-h3-line-height} $font-family-base;
}
@mixin font-h4() {
  font: $font-h4-font-weight #{$font-h4-font-size}/#{$font-h4-line-height} $font-family-base;
}
@mixin font-h5() {
  font: $font-h5-font-weight #{$font-h5-font-size}/#{$font-h5-line-height} $font-family-base;
}
@mixin font-h6() {
  font: $font-h6-font-weight #{$font-h6-font-size}/#{$font-h6-line-height} $font-family-base;
}
@mixin font-mobile-h1() {
  font: $font-mobile-h1-font-weight #{$font-mobile-h1-font-size}/#{$font-mobile-h1-line-height} $font-family-base;
}
@mixin font-mobile-h2() {
  font: $font-mobile-h2-font-weight #{$font-mobile-h2-font-size}/#{$font-mobile-h2-line-height} $font-family-base;
}
@mixin font-mobile-h3() {
  font: $font-mobile-h3-font-weight #{$font-mobile-h3-font-size}/#{$font-mobile-h3-line-height} $font-family-base;
}
@mixin font-mobile-h4() {
  font: $font-mobile-h4-font-weight #{$font-mobile-h4-font-size}/#{$font-mobile-h4-line-height} $font-family-base;
}
@mixin font-mobile-h5() {
  font: $font-mobile-h5-font-weight #{$font-mobile-h5-font-size}/#{$font-mobile-h5-line-height} $font-family-base;
}
@mixin font-mobile-h6() {
  font: $font-mobile-h6-font-weight #{$font-mobile-h6-font-size}/#{$font-mobile-h6-line-height} $font-family-base;
}
@mixin font-text-body() {
  font: $font-text-body-font-weight #{$font-text-body-font-size}/#{$font-text-body-line-height} $font-family-base;
}
@mixin font-text-bodybold() {
  font: $font-text-bodybold-font-weight #{$font-text-bodybold-font-size}/#{$font-text-bodybold-line-height} $font-family-base;
}
@mixin font-text-description() {
  font: $font-text-description-font-weight #{$font-text-description-font-size}/#{$font-text-description-line-height} $font-family-base;
}
