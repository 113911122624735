@import "@mtgame/ui/styles/tokens";
@import "@mtgame/ui/styles/brightness";
@import "figma-tokens";

$font-family-base: "Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

/* Colors */

$color-background--hover: darken($color-background, 4%);
$color-background--active: darken($color-background, 12%);
$color-background--lighten: lighten($color-background, 4%);

$color-primary-text: $color-colorfont;
$color-primary--hover: darken($color-primary, 4%);
$color-primary--active: darken($color-primary, 12%);
$color-primary--lighten: lighten($color-primary, 4%);

$color-secondary: #E8C592;
$color-secondary--hover: darken($color-secondary, 4%);
$color-secondary--active: darken($color-secondary, 12%);
$color-secondary--lighten: lighten($color-secondary, 4%);
$color-secondary-text: #000;

$color-alert--hover: darken($color-alert, 4%);
$color-alert--active: darken($color-alert, 12%);
$color-alert--lighten: lighten($color-alert, 4%);

$color-controlbase--hover: darken($color-controlbase, 4%);
$color-controlbase--active: darken($color-controlbase, 8%);
$color-controlbase--lighten: lighten($color-controlbase, 4%);

$color-controlelement--hover: darken($color-controlelement, 4%);
$color-controlelement--active: darken($color-controlelement, 12%);
$color-controlelement--lighten: lighten($color-controlelement, 4%);

$color-controlborder--hover: darken($color-controlborder, 4%);
$color-controlborder--active: darken($color-controlborder, 12%);
$color-controlborder--lighten: lighten($color-controlborder, 4%);

$color-cardbase--hover: darken($color-cardbase, 4%);
$color-cardbase--active: darken($color-cardbase, 12%);
$color-cardbase--lighten: lighten($color-cardbase, 4%);

$color-panel--hover: darken($color-panel, 4%);
$color-panel--active: darken($color-panel, 12%);
$color-panel--lighten: lighten($color-panel, 4%);

$color-chipsprimary--hover: darken($color-chipsprimary, 4%);
$color-chipsprimary--active: darken($color-chipsprimary, 12%);
$color-chipsprimary--lighten: lighten($color-chipsprimary, 4%);

$color-chipssecondary--hover: darken($color-chipssecondary, 4%);
$color-chipssecondary--active: darken($color-chipssecondary, 12%);
$color-chipssecondary--lighten: lighten($color-chipssecondary, 4%);


/** Css variables **/
$color-textsecondary: var(--color-textsecondary, $color-textsecondary);
$color-colorfont: var(--color-colorfont, $color-colorfont);

$color-background: var(--color-background, $color-background);
$color-background--hover: var(--color-background--hover, $color-background--hover);
$color-background--active: var(--color-background--active, $color-background--active);
$color-background--lighten: var(--color-background--lighten, $color-background--lighten);

$color-primary: var(--color-primary, $color-primary);
$color-primary--hover: var(--color-primary--hover, $color-primary--hover);
$color-primary--active: var(--color-primary--hover, $color-primary--active);
$color-primary--lighten: var(--color-primary--lighten, $color-primary--lighten);
$color-primary-text: var(--color-primary-text, $color-primary-text);
$color-primarybuttonfocus: var(--color-primarybuttonfocus, $color-primarybuttonfocus);

$color-secondary: var(--color-secondary, $color-secondary);
$color-secondary--hover: var(--color-secondary--hover, $color-secondary--hover);
$color-secondary--active: var(--color-secondary--active, $color-secondary--active);
$color-secondary--lighten: var(--color-secondary--lighten, $color-secondary--lighten);
$color-secondary-text: var(--color-secondary-text, $color-secondary-text);

$color-secondarybuttonfocus: var(--color-secondarybuttonfocus, $color-secondarybuttonfocus);

$color-cardbase: var(--color-cardbase, $color-cardbase);
$color-cardbase--hover: var(--color-cardbase--hover, $color-cardbase--hover);
$color-cardbase--active: var(--color-cardbase--active, $color-cardbase--active);
$color-cardbase--lighten: var(--color-cardbase--lighten, $color-cardbase--lighten);
$color-card-font: var(--color-card-font, $color-card-font);
$color-card-font-muted: var(--color-card-font-muted, $color-textsecondary);

$color-controlbase: var(--color-controlbase, $color-controlbase);
$color-controlbase--hover: var(--color-controlbase--hover, $color-controlbase--hover);
$color-controlbase--active: var(--color-controlbase--active, $color-controlbase--active);
$color-controlbase--lighten: var(--color-controlbase--lighten, $color-controlbase--lighten);
$color-control: var(--color-control, $color-control);
$color-control-muted: var(--color-control-muted, $color-textsecondary);
$color-textfield: var(--color-textfield, $color-textfield);

$color-panel: var(--color-panel, $color-panel);
$color-panel--hover: var(--color-panel--hover, $color-panel--hover);
$color-panel--active: var(--color-panel--active, $color-panel--active);
$color-panel--lighten: var(--color-panel--lighten, $color-panel--lighten);

$color-panel-font: var(--color-panel-font, $color-panel-font);
$color-panel-font-muted: var(--color-panel-font-muted, $color-textsecondary);

$color-panel-header: var(--color-panel-header, $color-panel-header);
$color-panel-header-text: var(--color-panel-header-text, $color-panel-header-text);

$color-controlelement: var(--color-controlelement, $color-controlelement);
$color-controlelement--hover: var(--color-controlelement--hover, $color-controlelement);
$color-controlelement--active: var(--color-controlelement--active, $color-controlelement--active);
$color-controlelement--lighten: var(--color-controlelement--lighten, $color-controlelement--lighten);

$color-controlborder: var(--color-controlborder, $color-controlborder);
$color-controlborder--hover: var(--color-controlborder--hover, $color-controlborder--hover);
$color-controlborder--active: var(--color-controlborder--active, $color-controlborder--active);
$color-controlborder--lighten: var(--color-controlborder--lighten, $color-controlborder--lighten);

$color-chipsprimary: var(--color-chipsprimary, $color-chipsprimary);
$color-chipsprimary--hover: var(--color-chipsprimary--hover, $color-chipsprimary--hover);
$color-chipsprimary--active: var(--color-chipsprimary--active, $color-chipsprimary--active);
$color-chipsprimary--lighten: var(--color-chipsprimary--lighten, $color-chipsprimary--lighten);

$color-chipssecondary: var(--color-chipssecondary, $color-chipssecondary);
$color-chipssecondary--hover: var(--color-chipssecondary--hover, $color-chipssecondary--hover);
$color-chipssecondary--active: var(--color-chipssecondary--active, $color-chipssecondary--active);
$color-chipssecondary--lighten: var(--color-chipssecondary--lighten, $color-chipssecondary--lighten);

$pattern-bg: var(--pattern-bg,);
$pattern-card: var(--pattern-card,);
$pattern-panel: var(--pattern-panel,);
$pattern-controlbase: var(--pattern-controlbase,);

/** Layout **/
$header-height: 64px;
$layoutZIndex: 100;
$content-vertical-padding: $spacing-xl;
$content-vertical-padding-tablet: $spacing-l;
$content-horizontal-padding: $spacing-s;
$content-horizontal-padding-mobile: $spacing-xs;
$content-min-width: 1200px + 2 * $content-horizontal-padding;

/* Device break points */
$tabletBreakpoint: 1050px;
$phoneBreakpoint: 700px;
$smallPhoneBreakpoint: 400px;

/* Transition */
$transition-time: .5s;
$transition-func: ease;
