@import "tokens";

.statistic-table {
  &.with-groups {
    .table-row {
      &.header {
        .table-cell {
          padding-top: 16px;
          justify-content: flex-end;
          &.identity {
            justify-content: flex-start;
          }
        }
      }
    }
  }
  .table-row{
    &.header {
      .table-cell-group-header {
        > .title {
          top: 28px;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .table-cell-group {
        margin-top: 65px;
      }
    }
  }
}

.statistic-table .table-row .table-cell {
  background: $pattern-panel $color-panel;
}

.statistic-table .table-row:nth-child(2n+1) .table-cell,
.statistic-table .table-row:nth-child(2n+1) .table-cell-group-header,
.statistic-table .table-row.header .table-cell-group-header > .title {
  background: $pattern-panel $color-panel--lighten;
}
